import React, { useContext, useState } from "react"
import styled from "@emotion/styled"

import Layout from "../components/layouts"
import OrderNumberForm from "../components/self-return/order-number-form"
import ReturnOrder from "../components/self-return/return-order"
import SEO from "../components/seo"
import NotificationContext from "../context/NotificationContext"
import Medusa from "../services/api"
import { Link } from "../components/v2/Link"

const StepContainer = styled.div`
  .step {
    .text {
      font-size: 18px;
      font-weight: 300;
    }

    .step-header {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .logistics-container {
      margin: 20px 0;
      .logistics {
        margin: 0;
        padding: 0;
      }
    }
  }
  margin: 70px 16px;
  .error {
    color: ${(props) => props.theme.colors.red};
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 100px auto;
    max-width: 1000px;

    .step {
      .text {
        font-size: 24px;
      }
    }
  }

  .form {
    max-width: 400px;
    margin: 40px auto;

    .form-input-field {
      padding-bottom: 20px;
    }
  }
`

const SelfReturn = () => {
  const [step, setStep] = useState(1)
  const [order, setOrder] = useState()
  const { pushNotification } = useContext(NotificationContext)

  const [returnDateExpired, setReturnDateExpired] = useState(false)

  const dateDifference = (dateInStringFormat) => {
    const orderDate = new Date(dateInStringFormat)

    const currentDate = new Date()

    return (currentDate - orderDate) / (1000 * 3600 * 24)
  }

  const isDateInHolidayReturnRange = (date) => {
    if (
      new Date(date) >= new Date("2023-11-09") &&
      new Date(date) <= new Date("2023-12-24") &&
      new Date() <= new Date("2024-01-31")
    ) {
      return true
    }

    return false
  }

  const handleOrderNumberSubmit = async (values) => {
    const { orderNumber, email, zipCode } = values

    Medusa.orders
      .lookup({
        display_id: orderNumber.trim(),
        email: email.trim(),
        "shipping_address[postal_code]": zipCode.trim(),
      })
      .then(async ({ data }) => {
        const order = data.order

        setReturnDateExpired(false)

        if (order.status === "canceled") {
          pushNotification({
            id: "not_shipped",
            body: "This order has been canceled. Contact support for more information.",
            dismiss: {
              duration: 3000,
            },
          })
          return
        }
        if (
          !(
            order.fulfillment_status === "shipped" ||
            order.fulfillment_status === "partially_returned"
          )
        ) {
          pushNotification({
            id: "not_shipped",
            body: "This order is not marked as shipped. Contact support for more information.",
            dismiss: {
              duration: 3000,
            },
          })
          return
        }

        if (
          order?.fulfillments?.length &&
          !isDateInHolidayReturnRange(order.created_at)
        ) {
          if (
            (order.fulfillment_status === "shipped" ||
              order.fulfillment_status === "partially_returned") &&
            dateDifference(order.fulfillments[0].shipped_at) > 30
          ) {
            setReturnDateExpired(true)
            return
          }
        }

        order.return_shipping = null
        order.items = order.items.reduce((acc, next) => {
          // if this item is fully returned, don't include it
          if (next.returned_quantity === next.quantity) {
            return acc
          }

          // if this item is partially returned, deduct it from the quantity available
          if (next.returned_quantity) {
            next.quantity -= next.returned_quantity
          }

          next.checked = false
          next.reason = null
          next.max_quantity = next.quantity

          // change unit price to be the refundable price
          next.unit_price = next.refundable / next.quantity

          acc.push(next)
          return acc
        }, [])

        // Initial state for return_shipping
        const selfReturnOption = {
          name: "self_return",
          id: "self-return-option",
          amount: 0,
        }

        const returnReasons = await Medusa.returnReasons
          .list()
          .then(({ data }) => data.return_reasons)
          .catch((err) => console.error(err))

        order.return_reasons = returnReasons.reduce((acc, next) => {
          const result = next
          result.display = next.label

          acc.push(result)
          return acc
        }, [])

        const shippingOptions = await Medusa.shippingOptions
          .list({
            is_return: true,
            region_id: order.region_id,
          })
          .then(({ data }) => data.shipping_options)
          .catch((err) => console.error(err))

        const options = shippingOptions
        // Append the free option
        options.push(selfReturnOption)
        order.return_shipping_options = options

        setOrder(order)
        setStep(2)
      })
      .catch((err) => console.error(err))
  }

  const handleSwapSubmit = () => {
    try {
      const items = order.items.reduce(
        (acc, next) => {
          if (next.checked) {
            if (!next.reason?.value) {
              throw new Error("no-reason")
            } else {
              acc.return_items.push({
                item_id: next.id,
                quantity: next.quantity,
                reason_id: next.reason.id,
              })

              if (next.swap_to) {
                acc.additional_items.push({
                  variant_id: next.swap_to.id,
                  quantity: next.quantity,
                })
              }
            }
          }

          return acc
        },
        { return_items: [], additional_items: [] }
      )

      if (items.length === 0) {
        throw new Error("no-items")
      }

      let swapOrder = {
        order_id: order.id,
        additional_items: items.additional_items,
        return_items: items.return_items,
      }

      // If the return is self return, dont append return_shipping to the API submit
      if (order.return_shipping.option_id !== "self-return-option") {
        swapOrder.return_shipping_option = order.return_shipping.option_id
      }

      let returnOrder
      if (swapOrder.additional_items.length === 0) {
        returnOrder = {
          order_id: swapOrder.order_id,
          items: swapOrder.return_items,
        }
        if (order.return_shipping.option_id !== "self-return-option") {
          returnOrder.return_shipping = {
            option_id: order.return_shipping.option_id,
          }
        }
      }

      const isReturn = Boolean(returnOrder.order_id)
      const payload = isReturn ? returnOrder : swapOrder

      Medusa[isReturn ? "returns" : "swaps"]
        .create(payload)
        .then(() => {
          pushNotification({
            id: "return-success",
            body: "Your return request has been received. Please follow the steps below to return items safely to our facilities.",
            dismiss: {
              duration: 3000,
            },
          })
          setStep(3)
        })
        .catch((err) => {
          console.error(err)

          pushNotification({
            id: "cant-swap",
            body: "Cannot create the exchange",
            dismiss: {
              duration: 3000,
            },
          })
        })
    } catch (err) {
      if (err.message === "no-reason") {
        pushNotification({
          id: "no-reason",
          body: "Remember to choose a reason",
          dismiss: {
            duration: 3000,
          },
        })
      } else if (err.message === "no-items") {
        pushNotification({
          id: "no-items",
          body: "No items were chosen for return",
          dismiss: {
            duration: 3000,
          },
        })
      } else {
        pushNotification({
          id: "cant-swap",
          body: "Cannot create the exchange",
          dismiss: {
            duration: 3000,
          },
        })
      }
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step">
            <h2 className="text">Manage your return - Step 1</h2>
            <p className="text">
              Please enter your order details below to start the return process.
            </p>

            <div className="form">
              <OrderNumberForm
                onSubmit={(values) => handleOrderNumberSubmit(values)}
                returnDateExpired={returnDateExpired}
              />
            </div>
            <p className="text">
              To exchange a product, please contact our customer service team
              via email at{" "}
              <Link
                variant="underline"
                href="mailto: customercare@teklafabrics.com"
              >
                customercare@teklafabrics.com
              </Link>
              . If your exchange is of a different value, our team can also
              assist with partial payments/refunds.
            </p>
          </div>
        )
      case 2:
        return (
          <div className="step">
            <h2 className="text">Manage your return - Step 2</h2>
            <p className="text">
              <>
                Select the items you wish to return, as well as the reasons you
                would like to return them. This information is incredibly
                valuable to us and we appreciate your feedback. If you
                experience any trouble during this step, please reach out to{" "}
                <Link
                  variant="underline"
                  href="mailto:customercare@teklafabrics.com"
                >
                  customercare@teklafabrics.com
                </Link>
                .
              </>
            </p>

            <ReturnOrder
              order={order}
              setOrder={setOrder}
              handleSwapSubmit={handleSwapSubmit}
            />
          </div>
        )
      case 3:
        return (
          <div className="step">
            <h2 className="text">
              Your return request has been received. Please follow the steps
              below to return items safely to our facilities.
            </h2>
            {order.return_shipping?.option_id === "self-return-option" ? (
              <div>
                <div>
                  <p className="step-header">Step 1</p>
                  <p>
                    Secure your items inside the original Tekla packaging and
                    take it to a courier of your choice.
                  </p>
                </div>
                <div>
                  <p className="step-header">Step 2</p>
                  <p>
                    Purchase a label and attach it securely to the package. Make
                    sure to save the tracking code and note the following
                    address for shipping
                  </p>
                  <div className="logistics-container">
                    <p className="logistics">Att: Tekla Fabrics</p>
                    <p className="logistics">Vallensbækvej 51-53</p>
                    <p className="logistics">2605 Brøndby</p>
                    <p className="logistics">Denmark</p>
                  </div>
                </div>
                <div>
                  <p className="step-header">Step 3</p>
                  <p>
                    Await email confirmation that the package has been received
                    in our facilities and that your refund is on its way to you.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <p className="step-header">Step 1</p>
                  <p>Print the return label sent to you via email.</p>
                </div>
                <div>
                  <p className="step-header">Step 2</p>
                  <p>
                    Secure your items inside the original Tekla packaging and
                    adhere the return label to the package.
                  </p>
                </div>
                <div>
                  <p className="step-header">Step 3</p>
                  <p>
                    Drop off the return package to one of the nearest shipping
                    locations for the provider of your choice.
                  </p>
                </div>
                <div>
                  <p className="step-header">Step 4</p>
                  <p>
                    Await email confirmation that the package has been received
                    in our facilities and that your refund is on its way to you.
                  </p>
                </div>
              </div>
            )}
          </div>
        )
    }
  }
  return (
    <Layout>
      <SEO title={"Return"} />
      <StepContainer>{renderStep()}</StepContainer>
    </Layout>
  )
}

export default SelfReturn
